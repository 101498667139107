<template>
    <div>
        <!-- CAROUSEL-->
    <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide                
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>

        <br>
        <!-- <h1 class="titles">
          
          <span style="color: #d82125">G</span>
          <span style="color: #d02175">o</span>
          <span style="color: #f6c921">b</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">e</span>
          <span style="color: #07aaba">r</span>
          <span style="color: #d82125">n</span>
          <span style="color: #d02175">o</span>
          
        </h1> -->

    <b-row align-h="center" align="center" class="mt-3 mb-3">
      <b-col cols="10" md="4" >
        <b-img  fluid src="https://teotihuacan.gob.mx/files/imagenes/odeapast.jpeg"></b-img>
      </b-col>
    </b-row>

    <h3 class="titles mt-5 " >
        <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Información Financiera LGCG</span>
        
    </h3>
    <b-row class="mt-5" align-h="center" >
        <b-col cols="11" md="10" lg="8" class="mb-5">
            <div class="accordion" role="tablist">

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-124 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2024</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-124"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20PRIMER%20TRIMESTRE%202024" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                          
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-224 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2024</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-224"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20SEGUNDO%20TRIMESTRE%202024" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                          
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-123 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-123"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20PRIMER%20TRIMESTRE%202023" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                          
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-223 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-223"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20SEGUNDO%20TRIMESTRE%202023" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                          
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-323 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Tercer Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-323"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20TERCER%20TRIMESTRE%202023/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                          
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-0 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-0"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20PRIMER%20TRIMESTRE%202022" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                          
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-02 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-02"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20SEGUNDO%20TRIMESTRE" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-03 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Tercer Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-03"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20DEL%20TERCER%20TRIMESTRE%202022/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-04 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Cuarto Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-04"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/INFORMACION%20FINANCIERA%20CUARTO%20TRIMESTRE" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-CP style="width:100%;   text-align:left; "><b-icon icon="files"/> Otra Información</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-CP"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/odapast/contabilidad/otrainformacion/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> SEVAC</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

            </div>
          </b-col>
        </b-row>


        
    
    <b-row align-h="center">
    
    <!--   <b-col cols="10" sm="8" md="6" lg="4" class="mt-5 mb-5" >
        <b-container>
          <b-img :src="require('../assets/MARIO OFICIAL.png')" fluid rounded class="c-shadow-sm" ></b-img>
        </b-container>
        <br>
        <b-div class="mt-5">
          <h4><span><b>C.P Mario Paredes de la Torre</b></span></h4>
          <h5><span>Presidente Municipal</span></h5>
        </b-div>
      </b-col>
     
          -->  
        
    </b-row>

    <b-row  v-for="empleado in resources.directorio_dif" :key="empleado.num" align-h="center" class="mt-5">
      <b-col cols="10" sm="6" md="4" lg="4" >
          <div :id="empleado.num"></div>
          <b-container class="mb-3">
            <b-img :src="'/files/directorio/dif/'+empleado.num+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
          <h4 ><b><b-icon icon="person-fill"/> {{empleado.nombre}}</b></h4>
          <h6>{{empleado.cargo}}</h6>
          <br>          
        </b-col>
    </b-row>

    </div>
</template>
<script>
  import axios from "axios";
  export default {
    components: {
    },
    mounted: function(){
      var host = location.protocol + '//' + location.host;
      axios({
          method: 'get',
          url: host+'/files/gobierno.json',
      })
      .then(res => {
          this.resources = res.data;
      }).catch(err => {
        alert(err);  
      });
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
      }
    }
    
  }
</script>